import { addresses } from "../App";
import style from "./Section6.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function Section6() {
    return (
        <div className={style.container}>
            <div className={style.text1}>{"A global community"}</div>
            <div className={style.text2}>
                {
                    "Want to learn more about Valkyrie protocol, join the discussion, and share your thoughts on the development and growth of the protocol? Join us."
                }
            </div>

            <div className={style.buttons}>
                <a
                    className={style.box}
                    href={addresses.twitter}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <div className={cx(style.icon, { twitter: true })} />
                    <div className={style.button}>{"Twitter"}</div>
                </a>
                <a
                    className={style.box}
                    href={addresses.telegram}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <div className={cx(style.icon, { telegram: true })} />
                    <div className={style.button}>{"Telegram"}</div>
                </a>
                <a
                    className={style.box}
                    href={addresses.medium}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <div className={cx(style.icon, { medium: true })} />
                    <div className={style.button}>{"Medium"}</div>
                </a>
                <a
                    className={style.box}
                    href={addresses.forum}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    <div className={cx(style.icon, { forum: true })} />
                    <div className={style.button}>{"Forum"}</div>
                </a>
            </div>
        </div>
    );
}
