import moment from "moment";
import { useEffect, useState } from "react";
import style from "./Section3.module.scss";

interface Medium {
    title: string;
    pubDate: string;
    link: string;
    thumbnail: string;
}

export default function Section3() {
    const cardWidth = 300;
    const cardMargin = 50;

    const [layout, setLayout] = useState({
        marginLeft: 0,
        marginRight: 0,
        width: 0,
    });
    const [cards, setCards] = useState<Medium[]>([]);

    function resized() {
        const width =
            cardWidth * cards.length + cardMargin * (cards.length - 1);

        const margin = (window.innerWidth - 1000) / 2;

        setLayout({
            marginLeft: margin,
            marginRight: margin,
            width: width,
        });
    }

    useEffect(() => {
        fetch(
            "https://api.rss2json.com/v1/api.json?rss_url=http://medium.com/feed/@valkyrie-protocol"
        )
            .then((r) => {
                console.log(r.body);
                return r.json();
            })
            .then((r) => {
                const items = r.items.map((item: Medium) => {
                    const d = moment(item.pubDate);

                    let title = item.title;
                    title = title.replaceAll("&gt;", ">");
                    title = title.replaceAll("&lt;", "<");

                    return {
                        title: title,
                        pubDate: d.format("MMM D"),
                        link: item.link,
                        thumbnail: item.thumbnail,
                    };
                });

                setCards(items.slice(0, 5));
            })
            .catch((e) => {});
    }, []);

    useEffect(() => {
        resized();
    }, [cards]);

    useEffect(() => {
        window.addEventListener("scroll", resized);
        window.addEventListener("resize", resized);
        return () => {
            window.removeEventListener("scroll", resized);
            window.removeEventListener("resize", resized);
        };
    }, [resized]);

    return (
        <div className={style.container}>
            <div className={style.text1}>{"In The News"}</div>
            <div className={style.cards_container}>
                <div
                    className={style.cards}
                    style={{
                        paddingLeft: layout.marginLeft + "px",
                        paddingRight: layout.marginRight + "px",
                        width: layout.width + "px",
                    }}
                >
                    {cards.map((item, index) => {
                        return <Card key={index} item={item} />;
                    })}
                </div>
            </div>
        </div>
    );
}

function Card(props: { item: Medium }) {
    return (
        <a
            className={style.card}
            href={props.item.link}
            target={"_blank"}
            rel={"noreferrer"}
        >
            <img className={style.image} src={props.item.thumbnail} alt="" />
            <div className={style.title}>{props.item.title}</div>
            <div className={style.date}>{props.item.pubDate}</div>
        </a>
    );
}
