import style from "./Section5.module.scss";
import hashed from "../resources/partners/hashed.png";
import arrington from "../resources/partners/arrington.png";
import struckcapital from "../resources/partners/struckcapital.png";
import vwynn from "../resources/partners/vwynn.png";
import gbic from "../resources/partners/gbic.png";
import a41 from "../resources/partners/a41.png";
import svc from "../resources/partners/svc.png";
import ventures from "../resources/partners/ventures.png";
import skynet from "../resources/partners/skynet.png";
import kanosei from "../resources/partners/kanosei.png";

export default function Section5() {
    return (
        <div className={style.container}>
            <div className={style.text1}>{"Partners"}</div>
            <div className={style.text2}>
                {
                    "Valkyrie builds a rewardable ecosystem where targeted actions are set and incentivized by participation and sharing of the generated campaigns."
                }
            </div>
            <div className={style.cards}>
                <Card icon={hashed} />
                <Card icon={arrington} />
                <Card icon={struckcapital} />
                <Card icon={vwynn} />
                <Card icon={gbic} />
                <Card icon={a41} />
                <Card icon={svc} />
                <Card icon={ventures} />
                <Card icon={skynet} />
                <Card icon={kanosei} />

                <Card icon={undefined} />
                <Card icon={undefined} />
            </div>
        </div>
    );
}

function Card(props: { icon: any }) {
    if (props.icon) {
        return (
            <div className={style.card}>
                <img src={props.icon} alt={""} />
            </div>
        );
    } else {
        return <div className={style.dummy} />;
    }
}
