import Header from "./View/Header";
import Section0 from "./View/Section0";

import Section1 from "./View/Section1";
import Section2 from "./View/Section2";
import Section3 from "./View/Section3";
import Section6 from "./View/Section6";

import Footer from "./View/Footer";
import "./App.scss";
import Section4 from "./View/Section4";
import Section5 from "./View/Section5";

export const addresses = {
    docs: "https://docs.valkyrieprotocol.com",
    forum: "https://forum.valkyrieprotocol.com/",
    twitter: "https://twitter.com/valkyrie_money",
    telegram: "https://t.co/rqsDkOWB4y",
    medium: "https://medium.com/@valkyrie-protocol",
    email: "mailto:info@valkyrieprotocol.com",
    whitepaper: "/whitepaper.pdf",
    webapp: "https://app.valkyrieprotocol.com/",
};

export default function App() {
    return (
        <div className="App">
            <Section0 />
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <Footer />

            <Header />
        </div>
    );
}
