import style from "./Section4.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(style);

export default function Section4() {
    return (
        <div className={style.container}>
            <div className={style.text1}>{"Roadmap"}</div>
            <div className={style.cards}>
                {/* <div style={{ height: "50px" }} /> */}
                <Card1 />
                <Card2 />
                <Card3 />
                <Card4 />
                <div className={style.bar} />
            </div>
        </div>
    );
}

function Card1() {
    return (
        <div className={style.card}>
            <Dot />
            <div className={style.content}>
                <div className={style.title}>{"2021.4Q"}</div>
                <div className={style.sub1}>
                    $VKR listing on Terraswap
                    <br />
                    Inaugural campaign with Pylon
                </div>
                <div className={style.sub2}>
                    Working with numerous teams to catalyze the Terra ecosystem
                </div>
                <div className={style.sub1}>
                    $VKR listing on global crypto exchanges
                </div>
                <div className={style.sub2}>
                    Listed on MEXC; more platforms coming
                </div>
                <div className={style.sub1}>“Valkyrian Ticket”</div>
                <div className={style.sub2}>Special incentives for stakers</div>
                <div className={style.sub1}>“IPC”</div>
                <div className={style.sub2}>
                    A new schema empowering protocols to reward first adopters
                    with early access to token generation events
                </div>
                <div style={{ height: "10px" }} />
            </div>
        </div>
    );
}

function Card2() {
    return (
        <div className={cx(style.card, { left: true })}>
            <Dot />
            <div className={cx(style.content, { left: true })}>
                <div className={cx(style.title, { left: true })}>
                    {"2022.1Q"}
                </div>
                <div className={cx(style.sub1, { left: true })}>
                    “Valkyrian Card”
                </div>
                <div className={cx(style.sub2, { left: true })}>
                    Utility-based NFTs unlocking additional Valkyrie rewards
                </div>
                <div className={cx(style.sub1, { left: true })}>
                    “Smart Airdrops”
                </div>
                <div className={cx(style.sub2, { left: true })}>
                    Innovating on airdrops to enable efficient, action-based
                    token distributions
                </div>
                <div className={cx(style.sub1, { left: true })}>
                    Data analysis and dev tooling
                </div>
                <div className={cx(style.sub2, { left: true })}>
                    An on-chain data review tool to analyze sharing in the
                    blockchain ecosystem
                </div>
                <div style={{ height: "10px" }} />
            </div>
        </div>
    );
}

function Card3() {
    return (
        <div className={style.card}>
            <Dot />
            <div className={style.content}>
                <div className={style.title}>{"2022.2Q"}</div>
                <div className={style.sub1}>Cross-chain support</div>
                <div className={style.sub2}>
                    Expand the Valkyrie Universe by joining the Cosmos ecosystem
                    via IBC
                </div>
                <div style={{ height: "10px" }} />
            </div>
        </div>
    );
}

function Card4() {
    return (
        <div className={cx(style.card, { left: true })}>
            <Dot />
            <div className={cx(style.content, { left: true })}>
                <div className={cx(style.title, { left: true })}>
                    {"2022.3Q"}
                </div>
                <div className={cx(style.sub1, { left: true })}>
                    Interoperable sharing
                </div>
                <div className={cx(style.sub2, { left: true })}>
                    Powering “Share to Earn” on Ethereum, Solana, BSC, and more
                </div>
            </div>
        </div>
    );
}

function Dot() {
    return (
        <div className={style.dot}>
            <div className={style.dot1} />
            <div className={style.dot2} />
            <div className={style.dot3} />
        </div>
    );
}
