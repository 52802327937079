import style from "./Section1.module.scss";

export default function Section1() {
    return (
        <div className={style.container}>
            <div className={style.icon} />
            <div className={style.text1}>{"Valkyrie Protocol"}</div>
            <div className={style.text2}>
                Valkyrie Protocol’s mission is to proliferate{" "}
                <b>“Share to Earn”</b> and powers DApp activations across the
                digital ecosystem. Through Valkyrie, protocols seeking to
                achieve a defined objective can create campaigns to promote
                their projects and set reward structures to incentivize user
                participation.
            </div>
        </div>
    );
}
