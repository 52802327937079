import { useState } from "react";
import { useEffect } from "react";
import style from "./Section0.module.scss";

export default function Section0() {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize(event: any) {
            setSize({
                width: window.innerHeight * 1.7778,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={style.section0}>
            <div className={style.container}>
                <video
                    className={style.video}
                    src="/web_valkyrie_intro.mp4"
                    width={size.width}
                    height={size.height}
                    playsInline
                    autoPlay
                    loop
                    muted
                />

                <div className={style.text1}>
                    <div className={style.text1_2}>{"Share to Earn"}</div>
                    <div className={style.text1_1}>{"Share to Earn"}</div>
                </div>

                <div className={style.text2}>
                    <div className={style.text1_2}>
                        Share
                        <br />
                        to
                        <br />
                        Earn
                    </div>
                    <div className={style.text1_1}>
                        Share
                        <br />
                        to
                        <br />
                        Earn
                    </div>
                </div>
            </div>
        </div>
    );
}
