import { useState } from "react";
import { useEffect } from "react";
import "./Header.scss";
import classNames from "classnames";
import { addresses } from "../App";

export default function Header() {
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        const body: any = document.querySelector("body");
        body.style.overflow = showMenu ? "hidden" : "auto";
    }, [showMenu]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            const obj1 = document.getElementById("menubutton");
            const obj2 = document.getElementById("mobile_menu");
            if (
                obj1 &&
                obj2 &&
                !(
                    (obj1 as any).contains(event.target) ||
                    (obj2 as any).contains(event.target)
                )
            ) {
                setShowMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMenu]);

    useEffect(() => {
        function handleResize(event: any) {
            if (window.innerWidth > 1200) {
                setShowMenu(false);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="header">
            <div className="container">
                <div className="logo" />

                <div className="buttons">
                    <a
                        className="button"
                        href={addresses.forum}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        {"Forum"}
                    </a>
                    <a
                        className="button"
                        href={addresses.docs}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        {"Docs"}
                    </a>
                    <a
                        className="webapp"
                        href={addresses.webapp}
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        {"Webapp"}
                    </a>
                </div>

                <div
                    id="menubutton"
                    className={classNames("menu", { show: showMenu })}
                    onClick={() => {
                        setShowMenu(!showMenu);
                    }}
                />
            </div>

            <Menu
                showMenu={showMenu}
                menuPressed={() => {
                    setShowMenu(false);
                }}
            />
        </div>
    );
}

function Menu(props: { showMenu: boolean; menuPressed: () => void }) {
    if (!props.showMenu) {
        return <div />;
    }

    return (
        <div className="mobile_menu_container">
            <div id="mobile_menu" className="mobile_menu">
                <a
                    className="item"
                    href={addresses.forum}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={props.menuPressed}
                >
                    {"Forum"}
                </a>
                <a
                    className="item"
                    href={addresses.docs}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={props.menuPressed}
                >
                    {"Docs"}
                </a>
                <a
                    className="item"
                    href={addresses.webapp}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={props.menuPressed}
                >
                    {"Webapp"}
                </a>
            </div>
        </div>
    );
}
