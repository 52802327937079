import { addresses } from "../App";
import "./Footer.scss";

export default function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <div className="copyright">
                    {"Copyright 2021. All right reserved."}
                </div>
                <a className="button" href={addresses.email}>
                    {"CONTACT"}
                </a>
                <a
                    className="button"
                    href={addresses.whitepaper}
                    target={"_blank"}
                    rel="noreferrer"
                >
                    {/* <div className="button disabled"> */}
                    {"WHITE PAPER"}
                </a>
            </div>
        </div>
    );
}
