import { useCallback, useEffect, useRef, useState } from "react";

import style from "./Section2.module.scss";

import img1 from "../resources/img-001.png";
import img2 from "../resources/img-002.png";
import img3 from "../resources/img-003.png";
import img4 from "../resources/img-004.png";
import img5 from "../resources/img-005.png";
import img6 from "../resources/img-006.png";
import img7 from "../resources/img-007.png";
import img8 from "../resources/img-008.png";
import img9 from "../resources/img-009.png";
import img10 from "../resources/img-010.png";

export default function Section2() {
    const cardWidth = 214;
    const cardMargin = 13;
    const cardSize = cardWidth + cardMargin;

    const initImages = [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
    ];
    const [cards, setCards] = useState([
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10,
    ]);

    useEffect(() => {
        resized();
    }, []);

    const resized = useCallback(() => {
        let list = initImages.slice();
        let cardsWidth = 0;

        while (cardsWidth < window.innerWidth) {
            for (let i = 0; i < initImages.length; i++) {
                list.push(initImages[i]);
            }
            cardsWidth = cardSize * list.length;
        }

        setCards(list);
    }, [cards]);

    useEffect(() => {
        const duration = cards.length * 3000;
        const e1 = document.getElementById("section2_cards1");
        const e2 = document.getElementById("section2_cards2");

        if (e1 && e2) {
            // // setInterval(() => {
            setInterval(() => {
                if (e1.offsetLeft + e1.clientWidth < 0) {
                    e1.style.left = e2.offsetLeft + e2.clientWidth + "px";
                }

                if (e2.offsetLeft + e2.clientWidth < 0) {
                    e2.style.left = e1.offsetLeft + e1.clientWidth + "px";
                }
            }, 10);

            e1.animate(
                [{ left: "0px" }, { left: e1.clientWidth * -1 + "px" }],
                {
                    duration: duration,
                    iterations: Infinity,
                }
            );
            e2.animate(
                [
                    { left: e1.offsetLeft + e1.clientWidth + "px" },
                    { left: "0px" },
                ],
                {
                    duration: duration,
                    iterations: Infinity,
                }
            );
        }

        window.addEventListener("resize", resized);
        return () => {
            window.removeEventListener("resize", resized);
        };
    }, [resized]);

    return (
        <div className={style.container}>
            <div className={style.text1}>{"Creator"}</div>
            <div className={style.cards_container}>
                <div
                    id={"section2_cards1"}
                    className={style.cards1}
                    style={{ width: cardSize * cards.length + "px" }}
                >
                    {cards.map((item, index) => {
                        return <Card key={index} index={index} item={item} />;
                    })}
                </div>
                <div
                    id={"section2_cards2"}
                    className={style.cards1}
                    style={{
                        left: cardSize * cards.length + "px",
                        width: cardSize * cards.length + "px",
                    }}
                >
                    {cards.map((item, index) => {
                        return <Card key={index} index={index} item={item} />;
                    })}
                </div>
            </div>

            <div className={style.shadow} />
        </div>
    );
}

function Card(props: { index: number; item: string }) {
    return (
        <img
            id={"section2_card_" + props.index}
            className={style.card}
            src={props.item}
            alt=""
        />
    );
}
